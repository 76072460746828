<template>
    <CCollapse :show="show" class="mt-2">
      <CCard body-wrapper>
        <CRow
          v-for="group, ind in groups"
          :key="ind"
        >
          <CCol
            v-for="part in group"
            :key="part.id"
            :col="12 / group.length"
          >
            <CInput
              disabled
              :label="part.handleHuman"
              :value="fullValue(part)"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="4">
            <CInput
              disabled
              label="Подъезд"
              :value="address.entrance"
            />
          </CCol>
          <CCol col="4">
            <CInput
              disabled
              label="Этаж"
              :value="address.floor"
            />
          </CCol>
        </CRow>
      </CCard>
    </CCollapse>
</template>

<script>
const GROUPS = [
  {items: ['region', 'area'], options: {hideEmpty: true}},
  {items: ['city', 'city_district', 'settlement'], options: {hideEmpty: true}},
  {items: ['street']},
  {items: ['house', 'block', 'flat']}
];


export default {
  name: 'AddressParts',
  props: {
    address: Object,
    show: {
      type: Boolean,
      default: false
    }
  },
  // watch: {
  //   show(val) {
  //     this.show = val;
  //   }
  // },
  // data() {
  //   return {
  //     show: false
  //   }
  // },
  computed: {
    groups() {
      const result = [];
      GROUPS.forEach(({items, options = {}}) => {
        const arr = items.map(el => this.address.parts.find(part => part.handle == el)).filter(el => options.hideEmpty ? el.value : true)
        result.push(arr)
      })
      console.log(result);
      return result;
    }
  },
  methods: {
    fullValue(item) {
      if (!item.value) {
        return ''
      }

      return `${item.fullType || ''} ${item.value}`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
