<template>
  <CCard body-wrapper>
    <CElementCover
      v-if="loading"
      :opacity="0.8"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
      />
    </CElementCover>
    <CForm v-if="store" @submit.prevent="saveStore">
      <CInput
        label="Название"
        required
        :invalid-feedback="getScalarFieldError(errors, 'name')"
        :is-valid="isScalarFieldInvalid(errors, 'name') ? false : null"
        :value.sync="store.name"
      />
      <CInput
        label="Полный адрес"
        :value="address"
        @input="changeAddress"
      >
        <template #append>
          <CButton
            v-if="addressIdentified != null"
            variant="outline"
            size="sm"
            :color="addressIdentified ? 'info' : 'danger'"
            :disabled="!addressIdentified"
            @click="showAddressInfo = !showAddressInfo"
          >
            Подробнее
          </CButton>
        </template>
      </CInput>
      <address-parts v-if="store.address" :address="store.address" :show="showAddressInfo" />
      <CSelect
        label="Внешний склад"
        :options="externalStoresSelectOptions"
        :value.sync="store.externalStoreId"
      />
      <loading-button class="mt-2" :loading="updating" />
    </CForm>
  </CCard>
</template>

<script>
import Swal from 'sweetalert2';
import FormMixins from '@/mixins/form-mixins';
import WithExternalStores from '@/mixins/with-external-stores';
import LoadingButton from '@/components/loading-button';
import AddressParts from '@/components/address-parts';

export default {
  name: 'ShowStore',
  mixins: [FormMixins, WithExternalStores],
  components: {
    LoadingButton,
    AddressParts
  },
  data() {
    return {
      store: null,
      errors: {},
      loading: false,
      updating: false,
      showAddressInfo: false,
    }
  },
  mounted() {
    this.loadExternalStores();
    this.loadStore();
  },
  computed: {
    storeId() {
      return this.$route.params.id
    },
    address() {
      if (!this.store.address) {
        return ''
      }

      return this.store.address.fullAddress
    },
    addressIdentified() {
      if (!this.store.address) {
        return null
      }

      return this.store.address.identified;
    },
    requestForm() {
      const res =  {...this.store, addressAttributes: this.store.address};
      delete res.address;
      return res;
    }
  },
  methods: {
    changeAddress(address) {
      if (!this.store.address) {
        this.store.address = {}
      }
      this.store.address.fullAddress = address;
    },
    async loadStore() {
      try {
        const {data} = await this.$axios.get(`/api/stores/${this.storeId}`);
        this.store = data;
      } catch(e) {
        console.log(e);
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Что-то пошло не так!',
        }).then(() => {
          this.$router.replace({name: 'admin.stores.index'});
        })
      } finally {
        this.loading = false;
      }
    },
    async saveStore() {
      try {
        this.updating = true;
        const {data} = await this.$axios.put(`/api/stores/${this.storeId}`, this.requestForm);
        this.store = data;
      } catch(e) {
        console.log(e);
        if (this.hasErrorsInResponse(e)) {
          this.errors = e.response.data

          this.$nextTick(() => {
            this.showBaseErrors();
          })
        }
      } finally {
        this.updating = false;
      }
    }
  }
}
</script>
