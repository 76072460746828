export default {
  data() {
    return {
      externalStores: {
        list: [],
        loading: false,
      }
    }
  },
  computed: {
    externalStoresSelectOptions() {
      return this.externalStores.list.map(el => ({label: el.name, value: el.id})).concat({label: 'Не выбрано', value: null});
    }
  },
  methods: {
    async loadExternalStores() {
      try {
        this.externalStores.loading = true;
        const {data} = await this.$axios.get(`/api/external_stores`);
        this.externalStores.list = data;
      } finally {
        this.externalStores.loading = false;
      }
    },
  }
}
